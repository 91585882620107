// @flow

// The value of clientCommitSha will be overwritten by the gitlab CI process
// using sed. See the .gitlab-ci.yml file to see the command being used.
const clientCommitSha = 'e01fa70d1ca9ecd9fa9da14dae94142096bd62c6';

const bitbucketBaseUrl = 'https://bitbucket.usit.uio.no/projects';
const clientRepoUrl = bitbucketBaseUrl + '/MUSIT/repos/musit-frontend';
const backendRepoUrl = bitbucketBaseUrl + '/MUSIT/repos/musit-backend';

export type VersionInfo = {
  sha: string;
  url?: string;
};

type BackendInfo = {
  commitSha: string;
};

function getBackendCommitSha(buildInfo?: BackendInfo) {
  if (buildInfo && buildInfo.commitSha) {
    return buildInfo.commitSha;
  }
  return '';
}

export function frontendVersion(): VersionInfo {
  if (clientCommitSha.length === 40) {
    const url = clientRepoUrl + '/commits/' + clientCommitSha;
    return { sha: clientCommitSha, url: url };
  }
  return { sha: clientCommitSha };
}

export function backendVersion(buildInfo?: BackendInfo): VersionInfo {
  const sha = getBackendCommitSha(buildInfo);
  if (sha.length === 40) {
    const url = backendRepoUrl + '/commits/' + sha;
    return { sha: sha, url: url };
  }
  return { sha: sha };
}
